export function mapEntityToPayload(entity) {
    return {
        farm_id: entity.farm && entity.farm.id || null,
        name: entity.name,
        type: entity.type,
        purposes: entity.purposes,
    };
}

export function mapEntityTypesResponse({entityTypes}) {
    return Object.keys(entityTypes).map(key => ({
        key,
        value: entityTypes[key],
    }));
}

export function mapEntityPurposesResponse({entityPurposes}) {
    return Object.keys(entityPurposes);
}